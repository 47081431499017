
@import '../assets/css/scss-variables.scss';

.comp-service-package {

  .preheadline {
    margin-bottom: calc(var(--spacer-em-half) * 3);
  }
  
  .subheadline-explanatory {
    margin-top: var(--spacer-em-half);
  }
  
  .service-packages {
    margin-top: var(--spacer-block-with-fallback);
  }
  
  .service-packages-divider-headline {
    font-weight: 900;
    font-size: var(--font-size-headline-bodytext);
    opacity: var(--opacity-medium);
    text-align: center;
  }
  
  .inquires {
    text-align: center;
    margin-top: var(--spacer);
    
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacer);
    
    p {
      hyphens: none;
      text-wrap: balance;
    }
    
    .service-packages-divider-headline {
      grid-column: 1 / -1;
    }
    
    .inquiry-single {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: calc(var(--spacer-em-half) * 2);

      .link-button {
        margin-top: calc(var(--spacer-em-half) * 2);
      }
      
      p:not([class]) {
        max-width: 40ch;
        /* text-wrap: balance; */
      }
    }
  }
}
