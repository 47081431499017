
// #router-element.page-contact {
// 	padding: unset;
	
// 	section:not(.containing-comp-list-subject-with-text) {
// 		padding-inline: var(--padding-outside);
// 	}
	
// 	section.containing-comp-list-subject-with-text {
// 		max-width: var(--max-width);
// 		margin-inline: auto;
// 	}
// }

.columns-grid {
	max-width: var(--max-width);
	margin-inline: auto;
}

.page-contact {
	--scale-text-to-button-factor: 1.15;
	
	section[data-section="contact-buttons"] {
		margin-top: var(--spacer-padding);
		
		.columns-grid {
			grid-template-columns:
				repeat(
					auto-fit,
					minmax(min((26ch + 10vmin), 100%), 1fr)
				);
			/* row-gap: calc(var(--column-gap) * 2); */
		}
		
		.description {
			margin-top: inherit;
		}
	}
	
	// *:not(.footer) * {
	// 	text-wrap: balance;
	// }
}
