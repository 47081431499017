
  @import '../assets/css/scss-variables.scss';

  .page-contact {
    // success messages from the button horizontal overflow
    overflow-x: hidden;
  }

  .comp-button-copy-paste {
    --_transition-speed: calc(var(--transition-speed) * 2);
    --_background-color: var(--white, white);
    --fallback-scale: 1.25;
    --_transition:
      background-color calc(var(--_transition-speed) * 1.5) var(--transition-easing),
      outline calc(var(--_transition-speed) * 1.5) var(--transition-easing),
      translate calc(var(--_transition-speed) * 1.5) var(--transition-easing);

    display: flex;
    flex-direction: column;
    gap: .5rem;
    
    .button-link {
      margin-top: unset;
      padding-inline-start: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
      text-align: left;
    }

    .button-link,
    .button-copy {
      font-size: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
      mix-blend-mode: multiply;
      background-color: var(--_background-color);
      transition: all 50ms ease-in-out;

      &.animation-playing {
        --_border-color: var(--gray-medium);

        .icon-copy-paste {
          --_outline-color: var(--gray-dark);
        }
      }

      @media (pointer: fine) {
        transition: var(--_transition);

        &:hover {
          translate: 0% -2%;
        }

        &:active {
          translate: 0% 2%;
        }
      }
    }
    
    .button-copy-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      aspect-ratio: 1 / 1;
      height: 100%;
    }

    .button-copy {
      width: 100%;
      height: 100%;
    }
    
    .button-outline {
      /* background-color: var(--gray-light); */
      // background-color: transparent;
      --_border-color: var(--gray-mediumlight);
      color: var(--black);
      outline: unset;
      border: var(--_border-color) 0.15em solid;
      border-radius: var(--border-radius);
    }
    
    .label {
      margin-inline-start: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
    }
    
    .description {
      max-width: 32ch;
      hyphens: none;
    }
    
    .button-copy-paste {
      position: relative;
      display: flex;
      gap: 0.5rem;
      
      * + * {
        margin-top: unset;
      }
      
      a {
        height: 100%;
        margin-top: unset;
        width: 100%;
        text-decoration: unset;
      }

      .success_message {
        position: absolute;
        top: -100%;
        translate: 0% 1rem;
        font-size: 1rem;
        right: -10%;
        text-align: right;
        width: 22ch;

        @media (min-width: $mediaquery-tablet-landscape) {
          text-align: center;
          right: unset;
        }
      }
    }

    .icon-copy-paste {
      --_translate-second-layer: 0.3em;
      --_outline-color: var(--gray-medium);

      display: flex;
      justify-content: center;
      align-items: center;
      transition-duration: calc(var(--_transition-speed) + .2s);

      .layer-front,
      .layer-back {
        --_backgorund-color: var(--white);
        height: .9em;
        aspect-ratio: 3 / 4.25;
        border-radius: calc(var(--border-radius) * .5);
        outline: .15em solid var(--_outline-color);
        position: absolute;
        background-color: var(--_backgorund-color);
        margin-top: calc(var(--_translate-second-layer) * .5);
        margin-right: calc(var(--_translate-second-layer) * .6);
        transition: var(--_transition);
      }

      .layer-front {
        box-shadow: 0px 0px 0px 0.15em var(--_backgorund-color);
        // transition-delay: .1s;
      }

      .layer-back {
        translate: var(--_translate-second-layer) calc(var(--_translate-second-layer) * -1);
        z-index: -1;
        transition-delay: .1s;
      }
    }
  }

	.bubble-ups-up-enter-active, .bubble-ups-up-leave-active {
		transition: opacity .5s var(--easing),
					transform .5s var(--easing);
	}

	.bubble-ups-up-enter {
		transform: translateY(50%);
		opacity: 0;
	}

	.bubble-ups-up-leave-to {
		opacity: 0;
	}
