
.comp-list-subject-with-text {
  margin-top: 1em;
    > * + * {
      /* margin-top: 2em; */
    }
  
  .subject-and-description-holder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--column-gap);
    overflow-x: scroll;

    width: calc(100% + var(--padding-outside) + var(--padding-outside));
    margin-left: calc(var(--padding-outside) * -1);
    // transform: translateX(-50%);
    // max-width: var(--max-width);
    padding-inline: var(--padding-outside);
    box-sizing: border-box;
    
    > * {
      padding-block: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 3.5rem);
      min-width: min(16rem, 65vmin);
    }
  }
  
  .subject {
    text-align: unset;
    margin-inline: unset;
    padding-inline-start: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
    hyphens: auto;
  }
  
  .description {
    margin-top: .25em;
    padding-inline: calc(var(--scale-text-to-button-factor, var(--fallback-scale)) * 1rem);
    text-wrap: initial !important;
    max-width: 38ch;
  }

  .description-richtext > * + * {
    margin-top: .75em;
  }

  .desciption-richtext-entry:has(.margin-top-big) {
    margin-top: 2em;
  }

  .desciption-richtext-job {

    & > * + * {
      margin-top: var(--spacer-em-half);
    }

    & + .desciption-richtext-job {
      position: relative;
      margin-top: 4em;

      &:before {
        content: "";
        position: absolute;
        top: -2.75em;
        height: var(--border-strenght);
        width: var(--length-line);
        background-color: var(--black);
        opacity: .6;
      }

      &.truncate {

        summary:marker {
          display: none;
        }
      }
    }
  }

  .desciption-richtext-list-bullet {
    display: inline-block;
    margin-bottom: unset;  
  }
}
