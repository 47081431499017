$mediaquery-phone: 400px;
$mediaquery-tablet-portrait: 768px;
$mediaquery-tablet-landscape: 1024px;
$mediaquery-laptop: 1660px;

:export {
	mediaqueryPhone: $mediaquery-phone;
	mediaqueryTablePortrait: $mediaquery-tablet-portrait;
	mediaqueryTableLandscape: $mediaquery-tablet-landscape;
	mediaqueryLaptop: $mediaquery-laptop;
}